import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import router from "@/router";
import routesName from "@/router/routesNames";
import HttpStatusCodes from "../common/HttpStatusCodes";
import User from "@/store/modules/User";
import { notificationWarning } from "@/utils/NotificationUtils";

import VueI18n from "vue-i18n";
import { messages, defaultLocale } from "@/i18n";
import IVueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);
const i18n = new IVueI18n({
  messages,
  locale: defaultLocale,
});

const AuthInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = User.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
};

const OnResponseSuccess = (response: AxiosResponse<any>): AxiosResponse<void> =>
  response;

const OnResponseFailure = (error: any): Promise<void> => {
  const httpStatus = error?.response?.status;
  const errors = [""];
  switch (httpStatus) {
    case HttpStatusCodes.UNAUTHORIZED:
      User.logout();
      router.push({ name: routesName.login });
      notificationWarning(`${i18n.t("general.error.session")}`);
      break;
    default:
      User.logout();
      router.push({ name: routesName.login });
      notificationWarning(`${i18n.t("general.error.connection")}`);
  }

  return Promise.reject(errors);
};

const instance: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000,
});

// instance.defaults.headers.get.Accepts = "application/json";
// instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

instance.interceptors.request.use(AuthInterceptor);

instance.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

export default instance;
