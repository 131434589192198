import routesNames from "@/router/routesNames";

export default [
  {
    path: "/boxes",
    name: routesNames.boxes,
    component: () => import("./Box.vue"),
    meta: {
      title: "Caixas",
      requiresAuth: false,
      anonymousOnly: false,
    },
  },
];
