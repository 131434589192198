import routesNames from "@/router/routesNames";

export default [
  {
    path: "/cars",
    name: routesNames.cars,
    component: () => import("./Cars.vue"),
    meta: {
      title: "Carros",
      requiresAuth: true,
      anonymousOnly: false,
    },
  },
];
