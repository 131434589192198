import routesNames from "@/router/routesNames";

export default [
  {
    path: "/regionals",
    name: routesNames.regionals,
    component: () => import("./Regionals.vue"),
    meta: {
      title: "Regionais",
      requiresAuth: true,
      anonymousOnly: false,
    },
  },
];
