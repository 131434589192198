import routesNames from "@/router/routesNames";

export default [
  {
    path: "/drivers",
    name: routesNames.drivers,
    component: () => import("./Drivers.vue"),
    meta: {
      title: "Motoristas",
      requiresAuth: true,
      anonymousOnly: false,
    },
  },
];
