export interface IRoutesNames {
  home: string;
  login: string;
  user: string;
  cars: string;
  drivers: string;
  boxes: string;
  regionals: string;
  itineraries: string;
  destinations: string;
}

const routesNames: Readonly<IRoutesNames> = {
  home: "home",
  login: "login",
  user: "users",
  cars: "cars",
  drivers: "drivers",
  boxes: "boxes",
  regionals: "regionals",
  itineraries: "itineraries",
  destinations: "destinations"
};

declare module "vue/types/vue" {
  interface Vue {
    $routesNames: IRoutesNames;
  }
}

export default routesNames;
