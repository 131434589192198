import Vue from "vue";
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from "vue-router";
import { routes as Auth } from "@/layouts/auth/index";
import { routes as Admin } from "@/layouts/admin/index";
import { routes as PageNotFound } from "@/layouts/pageNotFound";
import User from "@/store/modules/User";
import routesNames from "./routesNames";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [...PageNotFound, ...Auth, ...Admin];

const router = new VueRouter({
  routes,
});

const requiresAuthGuard = (to: Route, from: Route, next: NavigationGuardNext): boolean => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = !!User.isLoggedIn;
    if (!isLoggedIn) {
      next({
        name: routesNames.login,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
    return true;
  }
  return false;
};

const anonymousOnlyGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
): boolean => {
  if (to.matched.some((record) => record.meta.anonymousOnly)) {
    const isAnonymous = !User.user;
    if (!isAnonymous) {
      next({
        name: routesNames.home,
      });
    } else {
      next();
    }
    return true;
  }
  return false;
};

router.beforeEach(async (to, from, next) => {
  await User.completeAuth();
  document.title = `${to.meta?.title} :: Transporte - Adm`;
  if (requiresAuthGuard(to, from, next)) {
    return;
  }
  if (anonymousOnlyGuard(to, from, next)) {
    return;
  }
  next(); // make sure to always call next()!
});

export default router;
