import routesNames from "@/router/routesNames";

export default [
  {
    path: "/users",
    name: routesNames.user,
    component: () => import("./user.vue"),
    meta: {
      title: "Usuários",
      requiresAuth: false,
      anonymousOnly: false,
    },
  },
];
