import { routes as Home } from "@/views/home/index";
import { routes as Users } from "@/views/user/index";
import { routes as Cars } from "@/views/cars/index";
import { routes as Drivers } from "@/views/drivers";
import { routes as Boxes } from "@/views/box";
import { routes as Regionals } from "@/views/regionals";
import { routes as Itineraries } from "@/views/itineraries/index";
import { routes as Destinations } from "@/views/destinations/index";
export default [
  {
    path: "/admin",
    name: "admin",
    redirect: "/home",
    component: () => import("./admin.vue"),
    meta: {
      title: "Início"
    },
    children: [
      ...Home,
      ...Users,
      ...Cars,
      ...Drivers,
      ...Boxes,
      ...Regionals,
      ...Itineraries,
      ...Destinations
    ]
  }
];
