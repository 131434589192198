import routesNames from "@/router/routesNames";

export default [
  {
    path: "/destinations",
    name: routesNames.destinations,
    component: () => import("./Destinations.vue"),
    meta: {
      title: "Destinos",
      requiresAuth: true,
      anonymousOnly: false
    }
  }
];
