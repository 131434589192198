import routesNames from "@/router/routesNames";

export default [
  {
    path: "/itineraries",
    name: routesNames.itineraries,
    component: () => import("./Itineraries.vue"),
    meta: {
      title: "Itinerários",
      requiresAuth: true,
      anonymousOnly: false
    }
  }
];
